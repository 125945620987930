import { ImageDB } from "./db";

export type SortTypeArrayValue = 'dateRange' | 'dateNewer' | 'dateOlder' | 'label';
export const SortTypeArray: SortTypeArrayValue[] = [
    'dateRange',
    'dateNewer',
    'dateOlder',
    'label'
]

export const SortTypeName = {
    dateRange: '時間範囲指定',
    dateNewer: '新しい順',
    dateOlder: '古い順',
    label: 'ラベル'
}

export interface SortPage {
    pageData: ImageDB[],
    page: number,
    pageSize: number,
    pages: number
}