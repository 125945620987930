import { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, Paper, Input, Slider } from '@mui/material';
import { useWs } from '../../utils/connection';
import { image64ToFile, messageToJson } from '../../utils/converter';
import { sleep } from '../../utils/util';
import { Link } from 'react-router-dom';
import { VideocamOutlined } from '@mui/icons-material';

const PoseCamera: FC = () => {
  const wsContext = useWs();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  useEffect(() => {
    if (!wsContext.lastMessage) return;
    const jsonData = messageToJson(wsContext.lastMessage.data);
    switch (jsonData.type) {
      case 'poseImage':
        if (!jsonData.image || jsonData.image == '') return;
        setPoseedFrameCounter(poseedFrameCounter + 1);
        if (typeof jsonData.loadTime == 'string') return;
        setLastLoadTime(
          (jsonData.loadTime?.model as number) +
            (jsonData.loadTime?.nms as number)
        );

        const canvas = canvasRef.current;
        const ctx = canvas?.getContext('2d');
        if (!ctx || !canvas) return;

        const byteCharacters = atob(jsonData.image);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/jpeg' });
        const img = new Image();
        img.src = URL.createObjectURL(blob);
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          // 不要になったBlob URLを解放
          URL.revokeObjectURL(img.src);
        };
        break;
      default:
        break;
    }
  }, [wsContext.lastMessage]);

  const [frameRatio, setFrameRatio] = useState(4);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isCamera, setIsCamera] = useState(false);
  const [isPoseing, setIsPoseing] = useState(false);

  const [frameCounter, setFrameCounter] = useState(0);
  const [poseedFrameCounter, setPoseedFrameCounter] = useState(0);
  const [startTime, setStartTime] = useState(new Date());
  const [lastLoadTime, setLastLoadTime] = useState(0);

  useEffect(() => {
    setPoseedFrameCounter(0);
    setStartTime(new Date());

    let animationFrameId: number | undefined;
    const canvas = document.createElement('canvas');
    let frameCounter = 0;
    const captureFrame = () => {
      if (!isPoseing) return;
      if (!videoRef.current) return;

      animationFrameId = requestAnimationFrame(captureFrame);

      frameCounter++;
      setFrameCounter(frameCounter);
      if (frameCounter % frameRatio != 0) return;

      // canvas.width = videoRef.current.videoWidth;
      // canvas.height = videoRef.current.videoHeight;
      const maxWidth = 1000,
        maxHeight = 1000;
      let width = videoRef.current.videoWidth;
      let height = videoRef.current.videoHeight;
      if (width > maxWidth || height > maxHeight) {
        const aspectRatio = width / height;

        if (width > maxWidth) {
          width = maxWidth;
          height = width / aspectRatio;
        }

        if (height > maxHeight) {
          height = maxHeight;
          width = height * aspectRatio;
        }
      }

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');
      if (!ctx) return;
      ctx.drawImage(videoRef.current, 0, 0, width, height);

      const image64 = canvas
        .toDataURL('image/jpeg')
        .replace('data:image/jpeg;base64,', '');
      wsContext.sendJsonMessage({
        type: 'poseImage',
        image: image64,
        confThres: 0.2,
        iouThres: 0.45,
      });
    };

    if (isPoseing) animationFrameId = requestAnimationFrame(captureFrame);

    return () => {
      if (animationFrameId) cancelAnimationFrame(animationFrameId);
    };
  }, [isPoseing, frameRatio]);

  const startCamera = async () => {
    setIsCamera(!isCamera);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'environment', // リアカメラ
        },
      });
      if (!videoRef.current) return;
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
    return;
  };

  const stopCamera = () => {
    setIsCamera(!isCamera);
    if (!videoRef.current) return;
    videoRef.current.pause();
    return;
  };

  return (
    <Box
      sx={{
        textAlign: 'center',
        padding: '20px',
      }}
    >
      <h1>yolov7 - ポーズ検出</h1>
      <h2>リアルタイム検出版</h2>
      <h4>
        画像版は<Link to="/pose">こちら</Link>
      </h4>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper
            sx={{
              margin: 'auto',
            }}
          >
            <Box
              component="video"
              ref={videoRef}
              sx={{
                minWidth: '50vw',
                minHeight: '25vh',
                maxWidth: '90vw',
                maxHeight: '50vh',
              }}
            />
          </Paper>
        </Grid>

        <Grid item xs={6} md={3}>
          <Button
            variant="contained"
            color={isPoseing ? 'error' : 'success'}
            sx={(theme) => ({
              background: isPoseing
                ? theme.palette.error.main
                : theme.palette.success.light,
            })}
            onClick={() => {
              if (isCamera) setIsPoseing(!isPoseing);
            }}
          >
            {isPoseing ? '画像処理を停止' : '画像処理を開始'}
          </Button>
        </Grid>
        <Grid item xs={6} md={3}>
          <Button
            variant="contained"
            color={isCamera ? 'error' : 'primary'}
            onClick={
              isCamera
                ? () => {
                    stopCamera();
                    setIsPoseing(false);
                  }
                : startCamera
            }
          >
            {isCamera ? 'キャプチャを停止' : 'キャプチャを開始'}
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>フレーム倍率</Grid>
            <Grid item>
              <VideocamOutlined />
            </Grid>
            <Grid item xs>
              <Slider
                value={frameRatio}
                onChange={(event: Event, newValue: number | number[]) => {
                  setFrameRatio(newValue as number);
                }}
                aria-labelledby="input-slider"
                step={1}
                marks
                min={2}
                max={40}
              />
            </Grid>
            <Grid item>
              <Input
                value={frameRatio}
                size="small"
                onChange={(event) => {
                  setFrameRatio(Number(event.target.value));
                }}
                onBlur={() => {
                  if (frameRatio < 2) {
                    setFrameRatio(2);
                  } else if (frameRatio > 40) {
                    setFrameRatio(40);
                  }
                }}
                inputProps={{
                  step: 1,
                  min: 2,
                  max: 10,
                  type: 'number',
                  'aria-labelledby': 'input-slider',
                }}
                sx={{
                  width: 42,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item xs={1} md={2} /> */}
        <Grid item xs={12} md={4}>
          フレーム数: {frameCounter} - FPS:{' '}
          {Math.round(
            (frameCounter /
              ((new Date().getTime() - startTime.getTime()) / 1000)) *
              10
          ) / 10}
        </Grid>
        <Grid item xs={12} md={4}>
          処理済みフレーム数: {poseedFrameCounter} - FPS:{' '}
          {Math.round(
            (poseedFrameCounter /
              ((new Date().getTime() - startTime.getTime()) / 1000)) *
              10
          ) / 10}
        </Grid>
        <Grid item xs={12} md={4}>
          フレームごとの処理時間: {Math.round(lastLoadTime * 100) / 100}ms
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            marginBottom: 10,
          }}
        >
          <canvas
            ref={canvasRef}
            style={{
              minWidth: '50vw',
              minHeight: '25vh',
              maxWidth: '90vw',
              maxHeight: '80vh',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PoseCamera;
