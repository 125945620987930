import APIURL from "../../types/api";


export async function getDBState(pageSize: number = Number(process.env.REACT_APP_PAGESIZE)) {
    const query = new URLSearchParams({
        pageSize : pageSize.toString()
    });

    const response = await fetch(APIURL.db.state+'?' + query, {
        method: 'GET'
    });
    if (!response.ok) return null;
    return await response.json()
}

export default { getDBState };