import APIURL from "../../types/api";


export function getImageURL(imageId: string) {
    return APIURL.db.image.image+'/'+imageId;
}

export function getDetectImageURL(imageId: string) {
    return APIURL.db.image.detectImage+'/'+imageId;
}


export default { getImageURL, getDetectImageURL };