import styled from "@emotion/styled";
import { Close, DeleteForeverOutlined, FileDownloadOutlined } from "@mui/icons-material";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Paper } from "@mui/material";
import { formatBytes } from "../utils/util";

export const ImageFilePreview: React.FC<{
        file?: File,
        onClose: VoidFunction,
        isOpen: boolean | undefined,
        deleteImageFile?: ((targetNum: number)=>void),
        targetNum?: number,
        download?: boolean
    }> = ({file, isOpen, onClose, deleteImageFile, targetNum, download}) => {
    return (
        <Dialog open={!!isOpen}  onClose={onClose} maxWidth="lg">
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {file ? file.name : undefined} | {file ? formatBytes(file.size) : null}
            </DialogTitle>
            
            <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
            }}>
            <Close />
            </IconButton>
            
            <DialogContent dividers>
                <img src={file ? URL.createObjectURL(file) : ''}
                alt="プレビュー"
                style={{
                    // width: '60vw',
                    maxWidth: '100%',
                    maxHeight: '70vh'
                }}/>
                
                {deleteImageFile && (targetNum != undefined) && (
                    <IconButton
                    aria-label="delte"
                    onClick={()=>{
                        deleteImageFile(targetNum)
                        onClose()
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        bottom: 8,
                        color: (theme) => theme.palette.error.main,
                    }}>
                    <DeleteForeverOutlined />
                    </IconButton>
                )}
                {download && (
                    <a href={file ? URL.createObjectURL(file) : ''} download={new Date().getTime()}>
                        <IconButton // download button
                        aria-label="download"
                        sx={{
                            position: 'absolute',
                            right: 34,
                            bottom: 8,
                            cursor: 'pointer',
                            color: (theme) => theme.palette.secondary.dark,
                        }}>
                        <FileDownloadOutlined />
                        </IconButton>
                    </a>
                )}
            </DialogContent>
        </Dialog>
    );
}


export const ImagePreview: React.FC<{
    url?: string,
    isOpen: boolean | undefined,
    titleName: string;
    onClose: VoidFunction,
    deleteImageFile?: ((targetNum: number)=>void),
    targetNum?: number,
    download?: boolean
}> = ({url, isOpen, titleName, onClose, deleteImageFile, targetNum, download}) => {
    return (
        <Dialog open={!!isOpen}  onClose={onClose} maxWidth="lg">
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {titleName}
            </DialogTitle>
            
            <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
            }}>
            <Close />
            </IconButton>
            
            <DialogContent dividers>
                <img src={url ? url : ''}
                alt="プレビュー"
                style={{
                    // width: '60vw',
                    maxWidth: '100%',
                    maxHeight: '70vh'
                }}/>
                
                {deleteImageFile && (targetNum != undefined) && (
                    <IconButton
                    aria-label="delte"
                    onClick={()=>{
                        deleteImageFile(targetNum)
                        onClose()
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        bottom: 8,
                        color: (theme) => theme.palette.error.main,
                    }}>
                    <DeleteForeverOutlined />
                    </IconButton>
                )}
                {download && (
                    <a href={url ? url : ''} download={new Date().getTime()+'-'+titleName}>
                        <IconButton // download button
                        aria-label="download"
                        sx={{
                            position: 'absolute',
                            right: 34,
                            bottom: 8,
                            cursor: 'pointer',
                            color: (theme) => theme.palette.secondary.dark,
                        }}>
                        <FileDownloadOutlined />
                        </IconButton>
                    </a>
                )}
            </DialogContent>
        </Dialog>
    );
}
export default { ImageFilePreview }
