import config from "../config";


export async function detectImage(file: File, modelId: string | undefined = undefined, confThres=0.2, iouThres=0.2) {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('jsonData', JSON.stringify(Object.assign({
        confThres: confThres,
        iouThres: iouThres
    }, modelId == undefined ? undefined : {modelId: modelId})))
    return await fetch(config.APIServer.url+'/detectImage', {
        method: 'POST',
        body: formData,
    });
}

export default detectImage;