import APIURL from "../../types/api";
import { SortPage } from "../../types/sort";


export async function getSortLabel(page: number, labels: string[], pageSize: number = Number(process.env.REACT_APP_PAGESIZE)): Promise<SortPage | null> {
    const query = new URLSearchParams({
        type: 'label',
        page: page.toString(),
        pageSize : pageSize.toString(),
    });
    const queryLabels = labels.join('&label=')
    const response = await fetch(APIURL.db.sort+'?'+query+'&label='+queryLabels, {
        method: 'GET'
    });
    if (!response.ok) return null;
    return await response.json();
}

export default getSortLabel;