import React, { useContext } from "react";
import { wsMessage, wsSend } from '../types/connection' 
import config from "../config";

export const wsContext = React.createContext<{
    connectState: boolean;
    sessionId: string;
    lastMessage: MessageEvent<any> | null;
    messageHistory: wsMessage[];
    sendJsonMessage: (jsonMessage: wsSend, keep?: boolean) => void;
} | undefined>(undefined);
export function useWs() {
    const context = useContext(wsContext)
    if (context == undefined) {
        throw new Error("useWs is undefined");
    }
    return context;
}


export default { wsContext, useWs}