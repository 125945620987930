import config from "../config";
import { Models } from "../types/models";

export async function getModels(): Promise<Models | null> {
    try {
        const res = await fetch(config.APIServer.url+'/models', {
            method: 'GET'
        });
        if (!res.ok) return null;
    
        const data = await res.json() as Models;
        return data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export default getModels;