import { ChangeEvent, FC, MouseEvent, useEffect, useState } from "react";
import { Box, Button, CircularProgress, Grid, IconButton, ImageList, ImageListItem, List, ListItem, ListItemText, ListSubheader } from "@mui/material";
import { ItemPaper } from "../../components/ItemBox";
import { AddOutlined, Close, CloudUploadOutlined, DeleteForeverOutlined, FileDownloadOutlined, SearchOutlined } from "@mui/icons-material";
import { ImageFilePreview } from "../../components/Window";
import { fileToImage64, image64ToFile, resizeImage } from "../../utils/converter";
import { sleep } from "../../utils/util";
import { LinearProgressWithLabel } from "../../components/Progress";
import { Link } from "react-router-dom";
import config from "../../config";
import * as DetectImage from "../../types/detectImage";
import { detectImage } from "../../api/detectImage";
import { uploadImage } from "../../api/uploadImages";

const Upload: FC = () => {
    const [processing, setProcessing] = useState(false);
    const [processingNum, setProcessingNum] = useState(0)
    const [processedNum, setProcessedNum] = useState(0);
    // const wsContext = useWs();
    const imageUpload = async () => {
        setProcessing(true);
        setProcessingNum(imageFiles.length);

        let processedNum_ = processedNum, uploadedImageFiles_ = uploadedImageFiles, imageFiles_ = imageFiles;
        for(let i = 0; imageFiles.length > i; i++){
            try {
                const response = await uploadImage(imageFiles[i]);
                
                if (response.ok) {
                    // const jsonData: DetectImage.Response = await response.json();
                    uploadedImageFiles_ = uploadedImageFiles_.concat(imageFiles[i]);

                    const imageLimit = 20;
                    if (uploadedImageFiles_.length > imageLimit) uploadedImageFiles_.shift(); // リザルトが多すぎたら消す
                    
                    setUploadedImageFiles(uploadedImageFiles_);
                    imageFiles_ = imageFiles_.filter((file_) => (file_.name != imageFiles[i].name || file_.size != imageFiles[i].size))
                    setImageFiles(imageFiles_)
                } else {
                    console.error('File upload failed');
                }
            } catch (error) {
                console.error('ERROR: image detect', error);
            }
            setProcessedNum(++processedNum_);
            if (processedNum_ >= imageFiles.length) {
                setProcessing(false);
                setProcessingNum(0);
                setProcessedNum(0);
            }
        }
    }


    const [imageFiles, setImageFiles] = useState<File[]>([]);
    const [uploadedImageFiles, setUploadedImageFiles] = useState<File[]>([]);

    const addImageFiles = async(fileList: FileList) => {
        const files = Array.from(fileList)
            .filter((file) => file.type.startsWith('image/png')||file.type.startsWith('image/jpeg')
            );
            // && file.size <= 800000); // weboskcet使って送ると1MB超えると死ぬっポイ
        const imageLimit = 100;
        if (files.length >= imageLimit) files.splice(-(files.length - imageLimit)); // 添付したfileが最大file以上だったら削る
        

        const resizePromises = files.map(async (file, i) => {
            return files[i] = await resizeImage(files[i], 1200, 1200);
        });
        await Promise.all(resizePromises);
        // for (let i = 0; files.length > i; i++) {
        //     files[i] = await resizeImage(files[i], 1200, 1200); // 重くなるから圧縮
        // }
        if (imageFiles.length >= imageLimit) { // 画像100以上は消してく
            setImageFiles(imageFiles.slice(files.length).concat(files));
        } else {
            setImageFiles(imageFiles.concat(files));
        }
        return;
    }
    
    const deleteImageFile = (targetNum: number) => {
        const _ = [...imageFiles]
        _.splice(targetNum, 1)
        setImageFiles(_);
        return;
    }


    const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
    const [selectedFileNum, setSelectedFileNum] = useState<number>(0);

    const handleImageClick = (file: File, fileNum: number) => {
        setSelectedFile(file);
        setSelectedFileNum(fileNum);
    };
    const handleClosePreview = () => {
        setSelectedFile(undefined);
    };


    return (
    <Box sx={{
        textAlign: "center",
        padding: '20px',
    }}>
        <h1>画像を投稿</h1>
        <p>投稿された画像は<Link to="/images">こちら</Link></p>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                        xs:'repeat(auto-fill, minmax(100px, 1fr))',
                        md:'repeat(auto-fill, minmax(150px, 1fr))'
                    },
                    gap: '10px',
                    }}>
                        <Box sx={{
                        gridColumn: {
                            xs: 'span 3', // xsの場合、2マス分の幅を持たせる
                            md: 'span 2' // 'auto',   // mdの場合、通常の幅を持たせる
                        },
                        // width: { xs: 'auto', md: 150 },
                        width: { xs: 'auto', md: 'auto' },
                        height: { xs: 100, md: 150 }
                        }}>
                            <Button
                            onDrop={(e)=>{
                                e.preventDefault();
                                if (e.dataTransfer.types.indexOf("Files") == -1) return; // file以外
                                addImageFiles(e.dataTransfer.files);
                            }}
                            onDragOver={(e)=>{
                                e.preventDefault();
                                e.dataTransfer.dropEffect = "link";  
                            }}
                            component="label"
                            variant="contained"
                            sx={{
                                width: '100%',
                                height: '100%'
                            }}>
                            <AddOutlined />画像をドロップ<br/>もしくはクリック
                            <input type="file" accept="image/*"
                            multiple style={{ display: 'none' }}
                            onChange={(e)=>{
                                e.preventDefault();
                                if (!e.target.files) return;
                                addImageFiles(e.target.files);
                            }}/>
                            </Button>
                        </Box>
                    {
                    imageFiles.slice().reverse().map((imageFile, i)=>(
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'relative',
                            width: {
                                xs: 100,
                                md: 150
                            },
                            height: {
                                xs: 100,
                                md: 150
                            },
                            borderStyle: 'solid',
                            borderWidth: 1,
                            borderRadius: 2,
                            borderColor: (theme)=>(theme.palette.grey[800]),
                            overflow: 'hidden'
                        }}>
                            <IconButton
                            aria-label="close"
                            onClick={()=>{deleteImageFile(imageFiles.length - i - 1)}}
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                cursor: 'pointer',
                                color: (theme) => theme.palette.error.main,
                            }}>
                            <DeleteForeverOutlined />
                            </IconButton>
                            <Box component='img'
                            onClick={() => handleImageClick(imageFile, imageFiles.length - i - 1)}
                            src={URL.createObjectURL(imageFile)}
                            sx={{ height: '120%' }}/>
                            <ImageFilePreview download={false} file={selectedFile} isOpen={selectedFile && (selectedFileNum == imageFiles.length - i - 1)} onClose={handleClosePreview} deleteImageFile={deleteImageFile} targetNum={selectedFileNum} />
                        </Box>
                    ))
                    }
                </Box>
            </Grid>



            <Grid item xs={12}>
                <Button
                disabled={processing || !imageFiles.length}
                component="label"
                variant="contained"
                color="success"
                sx={{
                    background: (theme) => (theme.palette.success.light),
                    width: '100%',
                    height: '100%'
                }}
                onClick={imageUpload}>
                {
                processing ? (
                    <CircularProgress size={20} sx={{marginRight: 1}}/>
                ) : <CloudUploadOutlined sx={{marginRight: 1}}/>
                }
                
                画像をアップロード{processing ? " -  処理中..." : null}
                </Button>
            </Grid>


            {
            processing ? (
                <Grid item xs={12}>
                    <LinearProgressWithLabel value={(processedNum / processingNum)*100} />
                </Grid>
            ) : null
            }

            <Grid item xs={12}>
                <h3>送信済みの写真</h3>
                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                        xs:'repeat(auto-fill, minmax(70px, 1fr))',
                        md:'repeat(auto-fill, minmax(120px, 1fr))'
                    },
                    gap: '10px',
                    }}>
                    {
                    uploadedImageFiles.slice().reverse().map((imageFile, i)=>(
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'relative',
                            width: {
                                xs: 70,
                                md: 120
                            },
                            height: {
                                xs: 70,
                                md: 120
                            },
                            borderStyle: 'solid',
                            borderWidth: 1,
                            borderRadius: 2,
                            borderColor: (theme)=>(theme.palette.grey[800]),
                            overflow: 'hidden'
                        }}>
                            <Box component='img'
                            src={URL.createObjectURL(imageFile)}
                            sx={{
                                height: '120%'
                            }}
                            onClick={() => handleImageClick(imageFile, uploadedImageFiles.length - i - 1)}/>
                            <ImageFilePreview file={selectedFile} isOpen={selectedFile && (selectedFileNum == uploadedImageFiles.length - i - 1)} onClose={handleClosePreview} />
                        </Box>
                    ))
                    }
                </Box>
            </Grid>

            
        </Grid>

    </Box>
    );
};

export default Upload;
