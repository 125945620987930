import APIURL from "../../types/api";
import { SortPage } from "../../types/sort";

export async function getSortDateRange(startDate: number, endDate: number, page: number, pageSize: number = Number(process.env.REACT_APP_PAGESIZE)): Promise<SortPage | null> {
    const query = new URLSearchParams({
        type: 'dateRange',
        page: page.toString(),
        pageSize : pageSize.toString(),
        startDate: startDate.toString(),
        endDate: endDate.toString()
    });
    const response = await fetch(APIURL.db.sort+'?'+query, {
        method: 'GET'
    });
    if (!response.ok) return null;
    return await response.json();
}

export async function getSortNewer(page: number, pageSize: number = Number(process.env.REACT_APP_PAGESIZE)): Promise<SortPage | null> {
    const query = new URLSearchParams({
        type: 'dateNewer',
        page: page.toString(),
        pageSize : pageSize.toString()
    });
    const response = await fetch(APIURL.db.sort+'?'+query, {
        method: 'GET'
    });
    if (!response.ok) return null;
    return await response.json();
}

export async function getSortOlder(page: number, pageSize: number = Number(process.env.REACT_APP_PAGESIZE)): Promise<SortPage | null> {
    const query = new URLSearchParams({
        type: 'dateOlder',
        page: page.toString(),
        pageSize : pageSize.toString()
    });
    const response = await fetch(APIURL.db.sort+'?'+query, {
        method: 'GET'
    });
    if (!response.ok) return null;
    return await response.json();
}


export default { getSortDateRange, getSortNewer, getSortOlder }