import config from "../config";

const APIURL = {
    db: {
        state: config.APIServer.url+'/db/state',
        image: {
            image: config.APIServer.url+'/db/image',
            detectImage: config.APIServer.url+'/db/detectImage'
        },
        sort: config.APIServer.url+'/db/sort'
    }
}

export default APIURL