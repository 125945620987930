import { ChangeEvent, FC, MouseEvent, useEffect, useState } from "react";
import { Box, Button, CircularProgress, Grid, IconButton, ImageList, ImageListItem, List, ListItem, ListItemText, ListSubheader } from "@mui/material";
import { useWs } from "../../utils/connection";
import { ItemPaper } from "../../components/ItemBox";
import { AddOutlined, Close, DeleteForeverOutlined, FileDownloadOutlined, SearchOutlined } from "@mui/icons-material";
import { ImageFilePreview } from "../../components/Window";
import { fileToImage64, image64ToFile, messageToJson, resizeImage } from "../../utils/converter";
import { sleep } from "../../utils/util";
import { LinearProgressWithLabel } from "../../components/Progress";
import { Link } from "react-router-dom";

const Pose: FC = () => {
    const [processing, setProcessing] = useState(false);
    const [processingNum, setProcessingNum] = useState(0)
    const [processedNum, setProcessedNum] = useState(0);
    const wsContext = useWs();
    const imagePose = async () => {
        setProcessing(true);
        setProcessingNum(imageFiles.length);
        // (async () => {
        //     await sleep(30*1000); // 30秒たっても処理終わんなかったらタイムアウト
        //     setProcessing(false);
        //     setProcessingNum(0);
        // })()
        for(let i = 0; imageFiles.length > i; i++){
            await sleep(100);
            wsContext.sendJsonMessage({
                type: "poseImage",
                image: await fileToImage64(imageFiles[i]),
                confThres: 0.02,
                iouThres: 0.2
            });
        }
        // imageFiles.forEach(async(file, i) => {
        //     wsContext.sendJsonMessage({
        //         type: "poseImage",
        //         image: await fileToImage64(file),
        //         confThres: 0.2,
        //         iouThres: 0.45
        //     });
        // });
    }

    useEffect(()=>{
        if (!wsContext.lastMessage) return;
        const jsonData = messageToJson(wsContext.lastMessage.data);
        switch (jsonData.type) {
            case 'poseImage':
                setProcessedNum(processedNum + 1);
                if (!jsonData.image || jsonData.image == '') return;
                setPoseedImageFiles(poseedImageFiles.concat(image64ToFile(jsonData.image)));
                break;
            default:
                break;
        }
    }, [wsContext.lastMessage]);
    useEffect(()=>{ // 何らかの原因でサーバーから帰ってこない場合はボタン戻らなくなる
        if (processedNum >= processingNum) {
            setProcessing(false);
            setProcessingNum(0);
            setProcessedNum(0);
        }
    }, [processedNum]);

    const [imageFiles, setImageFiles] = useState<File[]>([]);
    const [poseedImageFiles, setPoseedImageFiles] = useState<File[]>([]);

    const addImageFiles = async(fileList: FileList) => {
        const files = Array.from(fileList)
            .filter((file) => file.type.startsWith('image/png')||file.type.startsWith('image/jpeg')
            );
            // && file.size <= 800000); // weboskcet使って送ると1MB超えると死ぬっポイ
        const imageLimit = 30;
        if (files.length >= imageLimit) files.splice(-(files.length - imageLimit)); // 添付したfileが最大file以上だったら削る
        
        for (let i = 0; files.length > i; i++) {
            files[i] = await resizeImage(files[i], 1200, 1200); // 重くなるから圧縮
        }
        if (imageFiles.length >= imageLimit) { // 画像30以上は消してく
            setImageFiles(imageFiles.slice(files.length).concat(files));
        } else {
            setImageFiles(imageFiles.concat(files));
        }
        return;
    }
    
    const deleteImageFile = (targetNum: number) => {
        const _ = [...imageFiles]
        _.splice(targetNum, 1)
        setImageFiles(_);
        return;
    }
    const deletePoseedImageFiles = (targetNum: number) => {
        const _ = [...poseedImageFiles]
        _.splice(targetNum, 1)
        setPoseedImageFiles(_);
        return;
    }


    const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
    const [selectedFileNum, setSelectedFileNum] = useState<number>(0);

    const handleImageClick = (file: File, fileNum: number) => {
        setSelectedFile(file);
        setSelectedFileNum(fileNum);
    };
    const handleClosePreview = () => {
        setSelectedFile(undefined);
    };


    return (
    <Box sx={{
        textAlign: "center",
        padding: '20px',
    }}>
        <h1>yolov7 - 画像検出</h1>
        <h4>カメラアルタイム版は<Link to="/pose/camera">こちら</Link></h4>
        <Grid container spacing={2}>
            {/* <Grid item xs={12}>
            <div style={{
                display: 'flex',
                flexWrap: 'wrap'
            }}>
                {
                    [...Array(5)].map((_, i)=>(
                        <div>
                            <Box sx={(theme)=>({
                                width: 200,
                                height: 200,
                                borderStyle: 'solid',
                                // borderColor: alpha(theme.palette.primary.light, 0.2),
                                borderWidth: 1,
                                borderRadius: 2
                            })}></Box>
                        </div>
                    ))
                }
                </div>
            </Grid> */}
            <Grid item xs={12}>
                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                        xs:'repeat(auto-fill, minmax(80px, 1fr))',
                        sm:'repeat(auto-fill, minmax(150px, 1fr))'
                    },
                    gap: '10px',
                    }}>
                        <Box sx={{
                        gridColumn: {
                            xs: 'span 3', // xsの場合、2マス分の幅を持たせる
                            sm: 'span 2' // 'auto',   // smの場合、通常の幅を持たせる
                        },
                        // width: { xs: 'auto', sm: 150 },
                        width: { xs: 'auto', sm: 'auto' },
                        height: { xs: 80, sm: 150 }
                        }}>
                            <Button
                            onDrop={(e)=>{
                                e.preventDefault();
                                if (e.dataTransfer.types.indexOf("Files") == -1) return; // file以外
                                addImageFiles(e.dataTransfer.files);
                            }}
                            onDragOver={(e)=>{
                                e.preventDefault();
                                e.dataTransfer.dropEffect = "link";  
                            }}
                            component="label"
                            variant="contained"
                            sx={{
                                width: '100%',
                                height: '100%'
                            }}>
                            <AddOutlined />画像をドロップ<br/>もしくはクリック
                            <input type="file" accept="image/*"
                            multiple style={{ display: 'none' }}
                            onChange={(e)=>{
                                e.preventDefault();
                                if (!e.target.files) return;
                                addImageFiles(e.target.files);
                            }}/>
                            </Button>
                        </Box>
                    {
                    imageFiles.slice().reverse().map((imageFile, i)=>(
                        <Box sx={{
                            position: 'relative',
                            width: {
                                xs: 80,
                                sm: 150
                            },
                            height: {
                                xs: 80,
                                sm: 150
                            },
                            borderStyle: 'solid',
                            borderWidth: 1,
                            borderRadius: 2,
                            overflow: 'hidden'
                        }}>
                            <IconButton
                            aria-label="close"
                            onClick={()=>{deleteImageFile(imageFiles.length - i - 1)}}
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                cursor: 'pointer',
                                color: (theme) => theme.palette.error.main,
                            }}>
                            <DeleteForeverOutlined />
                            </IconButton>
                            <Box component='img'
                            src={URL.createObjectURL(imageFile)}
                            sx={{
                                height: {
                                    xs: 80,
                                    sm: 150
                                },
                                objectPosition: 'center'
                            }}
                            onClick={() => handleImageClick(imageFile, imageFiles.length - i - 1)}/>
                            <ImageFilePreview download={false} file={selectedFile} isOpen={selectedFile && (selectedFileNum == imageFiles.length - i - 1)} onClose={handleClosePreview} deleteImageFile={deleteImageFile} targetNum={selectedFileNum} />
                        </Box>
                    ))
                    }
                </Box>
            </Grid>








            <Grid item xs={12}>
                <Button
                disabled={processing || !wsContext.connectState || !imageFiles.length}
                component="label"
                variant="contained"
                color="success"
                sx={{
                    background: (theme) => (theme.palette.success.light),
                    width: '100%',
                    height: '100%'
                }}
                onClick={imagePose}>
                {
                processing ? (
                    <CircularProgress size={20} sx={{marginRight: 1}}/>
                ) : <SearchOutlined sx={{marginRight: 1}}/>
                }
                
                画像を検出！{processing ? " -  処理中..." : null}
                </Button>
            </Grid>
            {
            processing ? (
                <Grid item xs={12}>
                    <LinearProgressWithLabel value={(processedNum / processingNum)*100} />
                </Grid>
            ) : null
            }
            <Grid item xs={12}>
                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                        xs:'repeat(auto-fill, minmax(130px, 1fr))',
                        sm:'repeat(auto-fill, minmax(150px, 1fr))'
                    },
                    gap: '10px',
                    }}>
                    {
                    poseedImageFiles.slice().reverse().map((imageFile, i)=>(
                        <Box sx={{
                            position: 'relative',
                            width: {
                                xs: 130,
                                sm: 150
                            },
                            height: {
                                xs: 130,
                                sm: 150
                            },
                            borderStyle: 'solid',
                            borderWidth: 1,
                            borderRadius: 2,
                            overflow: 'hidden'
                        }}>

                            <IconButton // delete button
                            aria-label="delete"
                            onClick={()=>{deletePoseedImageFiles(poseedImageFiles.length - i - 1)}}
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                cursor: 'pointer',
                                color: (theme) => theme.palette.error.main,
                            }}>
                            <DeleteForeverOutlined />
                            </IconButton>

                            <a href={URL.createObjectURL(imageFile)} download={new Date().getTime()}>
                                <IconButton // download button
                                aria-label="download"
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 25,
                                    cursor: 'pointer',
                                    color: (theme) => theme.palette.secondary.dark,
                                }}>
                                <FileDownloadOutlined />

                                </IconButton>
                            </a>
                            
                            <Box component='img'
                            src={URL.createObjectURL(imageFile)}
                            sx={{
                                height: {
                                    xs: 130,
                                    sm: 150
                                },
                                objectPosition: 'center'
                            }}
                            onClick={() => handleImageClick(imageFile, poseedImageFiles.length - i - 1)}/>
                            <ImageFilePreview download={true} file={selectedFile} isOpen={selectedFile && (selectedFileNum == poseedImageFiles.length - i - 1)} onClose={handleClosePreview} deleteImageFile={deletePoseedImageFiles} targetNum={selectedFileNum} />
                        </Box>
                    ))
                    }
                </Box>
            </Grid>
        </Grid>

    </Box>
    );
};

export default Pose;
