import { FC, useEffect, useState } from "react";
import { Autocomplete, Box, Chip, CircularProgress, Divider, FormControl, Grid, InputLabel, MenuItem,Pagination, Select, SelectChangeEvent, Stack, TextField, alpha, useMediaQuery, useTheme } from "@mui/material";
import { ClearOutlined } from "@mui/icons-material";
import { ImagePreview } from "../../components/Window";
import { getDBState } from "../../api/db/DBState";
import { DBState, ImageDB } from "../../types/db";
import { SortTypeArray, SortTypeArrayValue, SortTypeName } from "../../types/sort";
import { getDetectImageURL } from "../../api/db/Image";
import { getSortLabel } from "../../api/db/sortLabel";
import { getSortNewer, getSortOlder } from "../../api/db/sortDate";
import { Link } from "react-router-dom";

const Images: FC = () => {
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('xs'));
    const isSM = useMediaQuery(theme.breakpoints.down('sm'));
    const isMD = useMediaQuery(theme.breakpoints.down('md'));


    const [DBState, setDBState] = useState<DBState | undefined>(undefined);
    const [labelFieldLoading, setLabelFieldLoading] = useState<boolean>(false);
    // const [labelFieldOpen, setLabelFieldOpen] = useState<boolean>(false);
    

    const [sortType, setSortType] = useState<SortTypeArrayValue>('dateNewer');
    const [selectLabels, setSelectLabels] = useState<string[]>([]);
    const [pages, setPages] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(1);

    const [sortResults, setSortResults] = useState<ImageDB[]>([]);

    const [selectedImageURL, setSelectedImageURL] = useState<string | undefined>(undefined);
    const [selectedImageURLNum, setSelectedImageURLNum] = useState<number>(0);

    // useEffect(()=>{ // 初期データ取得
    //     (async()=>{
    //         const jsonDate = await getDBState(pageSize);
    //         if (!jsonDate) return;
    //         setDBState(jsonDate);
    //     })();
    // }, []);
    const initDBState = async() => {
        const jsonDate = await getDBState(pageSize);
        if (!jsonDate) return;
        setDBState(jsonDate);
    }


    useEffect(()=>{ // ラベルが入力されたらそれでソート
        (async()=>{
            if (!selectLabels.length) {
                const dataJson = await getSortNewer(page, pageSize);
                if (dataJson) {
                    setSortResults(dataJson.pageData);
                    setPage(1);
                    setPages(dataJson.pages);
                    return;
                }
                setSortResults([]);
                return;
            }
            const dataJson = await getSortLabel(page, selectLabels, pageSize);
            if (dataJson) {
                setSortResults(dataJson.pageData);
                setPages(dataJson.pages);
                return;
            }
            setSortResults([]);
        })();
    }, [selectLabels])
    useEffect(()=>{ // ページサイズ変更時
        (async()=>{
            if (sortType == 'label') {
                if (!selectLabels.length) return;
                const dataJson = await getSortLabel(page, selectLabels, pageSize);
                if (dataJson) {
                    setSortResults(dataJson.pageData);
                    setPage(1);
                    setPages(dataJson.pages);
                    return;
                }
                setSortResults([]);
                return;
            } else if (sortType == 'dateNewer') {
                const dataJson = await getSortNewer(page, pageSize);
                if (dataJson) {
                    setSortResults(dataJson.pageData);
                    setPage(1);
                    setPages(dataJson.pages);
                    return;
                }
                setSortResults([]);
                return;
            } else if (sortType == 'dateOlder') {
                const dataJson = await getSortOlder(page, pageSize);
                if (dataJson) {
                    setSortResults(dataJson.pageData);
                    setPage(1);
                    setPages(dataJson.pages);
                    return;
                }
                setSortResults([]);
                return;
            }
        })();
    }, [pageSize])
    useEffect(()=>{ // ページサイズ変更時
        (async()=>{
            if (sortType == 'label') {
                if (!selectLabels.length) { // ラベル選択してないときは新しい順
                    const dataJson = await getSortNewer(page, pageSize);
                    if (dataJson) {
                        setSortResults(dataJson.pageData);
                        setPages(dataJson.pages);
                        return;
                    }
                    setSortResults([]);
                    return;
                };
                const dataJson = await getSortLabel(page, selectLabels, pageSize);
                if (dataJson) {
                    setSortResults(dataJson.pageData);
                    setPages(dataJson.pages);
                    return;
                }
                setSortResults([]);
                return;
            } else if (sortType == 'dateNewer') {
                const dataJson = await getSortNewer(page, pageSize);
                if (dataJson) {
                    setSortResults(dataJson.pageData);
                    setPages(dataJson.pages);
                    return;
                }
                setSortResults([]);
                return;
            } else if (sortType == 'dateOlder') {
                const dataJson = await getSortOlder(page, pageSize);
                if (dataJson) {
                    setSortResults(dataJson.pageData);
                    setPages(dataJson.pages);
                    return;
                }
                setSortResults([]);
                return;
            }
        })();
    }, [sortType, page])

    const handleSortTypeChange = (event: SelectChangeEvent) => {
        const newSortType = event.target.value as SortTypeArrayValue;
        setSortType(newSortType);
    };
    
    
    const handleImageClick = (imageURL: string, imageNum: number) => {
        setSelectedImageURL(imageURL);
        setSelectedImageURLNum(imageNum);
    };
    const handleClosePreview = () => {
        setSelectedImageURL(undefined);
    };


    return (
    <Box sx={{
        textAlign: "center",
        padding: '20px',
    }}>
        <h1>投稿された画像</h1>
        <p>画像投稿は<Link to="/upload">こちら</Link></p>
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{
                display: {
                    sm: 'flex', // 横並び
                    sx: 'block'
                },
                justifyContent: 'space-between' // 均等に分ける
            }}>
            <Box sx={{mt: 2}}>
                <FormControl sx={{width: 200}}>
                    <InputLabel variant='outlined'>ソートタイプ</InputLabel>
                    <Select
                    variant='outlined'
                    value={sortType}
                    onChange={handleSortTypeChange}
                    label='ソートタイプ'
                    >
                    {SortTypeArray.map((sortType) => (
                        <MenuItem
                        key={sortType}
                        value={sortType}>
                            {SortTypeName[sortType]}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
            </Box>
            {sortType == 'label' && (
                    <Box sx={{
                        mt: 2,
                        ml: {sm: 10, xs: 'auto'},
                        width: '100%'
                    }}>
                        <Autocomplete
                        loading={labelFieldLoading}
                        loadingText={'ラベルを取得中...'}
                        onOpen={async() => {
                            setLabelFieldLoading(true);
                            await initDBState();
                            setLabelFieldLoading(false);
                        }}
                        multiple
                        disableCloseOnSelect
                        options={DBState == undefined ? [] : DBState.labels.slice().sort((a, b) => b.num - a.num).map((label) => label.name)}
                        getOptionLabel={(option) => {
                            const label = DBState?.labels.filter((label) => label.name == option)[0];
                            if (!label) return 'none';
                            return label.name + ' ' + label.num;
                        }}
                        value={selectLabels}
                        onChange={(event, newValue) => {
                            setSelectLabels(newValue as string[]);
                        }}
                        noOptionsText={'ラベルが存在しません'}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            InputProps={{
                            ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {labelFieldLoading && <CircularProgress color="inherit" size={20} />}
                                        {params.InputProps.endAdornment // 閉じるボタン
                                        }
                                    </>
                                ),
                            }}
                            variant='outlined'
                            label='ラベルを検索'
                            placeholder={DBState == undefined ? 'tvs' : DBState.labels.map((label) => label.name)[Math.floor(Math.random() * DBState.labels.map((label) => label.name).length)]} // ラベルの中から毎回ランダムで適当に表示
                            />
                        )}
                        />
                    </Box>
            )}
            {/* {sortType == 'dateRange' && (
                    <Box sx={{
                        mt: 2,
                        ml: {sm: 10, xs: 'auto'},
                        width: '100%'
                    }}>

                    </Box>
            )} */}
            </Grid>

            <Grid item xs={12}>
                {sortResults?.length > 0 ?
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: {
                            xs:'repeat(auto-fill, minmax(150px, 1fr))',
                            md:'repeat(auto-fill, minmax(300px, 1fr))'
                        },
                        gap: '10px',
                    }}>

                        {sortResults?.map((sortResult, i)=>(
                            <Box sx={{
                                position: 'relative',
                                width: {
                                    xs: 150,
                                    md: 300
                                },
                                height: {
                                    xs: 150,
                                    md: 300
                                },
                                borderStyle: 'solid',
                                borderWidth: 1,
                                borderRadius: 2,
                                borderColor: (theme)=>(theme.palette.grey[800]),
                                overflow: 'hidden'
                            }}>
                                {/* <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    height: '80%',
                                    borderBottomStyle: 'solid',
                                    borderBottomWidth: 1,
                                    borderColor: (theme)=>(theme.palette.grey[800]),
                                    overflow: 'hidden'
                                }}>
                                    <Box component='img'
                                    onClick={() => handleImageClick(getDetectImageURL(sortResult.id), sortResults.length - i - 1)}
                                    src={getDetectImageURL(sortResult.id)}
                                    sx={{
                                        height: '150%',
                                        transform: 'translate(0%, -10%)'
                                    }}/>
                                    <ImagePreview download={true} url={selectedImageURL} onClose={handleClosePreview} titleName={sortResult.id}/>
                                </Box>
                                <Box sx={{ overflow: 'scroll', m: '2%', textAlign: 'left' }}>
                                    {sortResult.labels.slice().sort((a, b) => b.num - a.num).map((label)=>(
                                        <Chip label={label.num+' '+label.name}
                                        variant='filled'
                                        size={ isMD ? 'small' : 'medium' }
                                        sx={{
                                            ml:1,
                                            // display: 'inline',
                                        }}/>
                                    ))}
                                </Box> */}


                                <Box sx={{
                                    position: 'relative',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    height: '100%',
                                    overflow: 'hidden'
                                }}>
                                    <Box component='img'
                                    onClick={() => handleImageClick(getDetectImageURL(sortResult.id), sortResults.length - i - 1)}
                                    src={getDetectImageURL(sortResult.id)}
                                    sx={{
                                        height: '150%',
                                        transform: 'translate(0%, -10%)'
                                    }}/>
                                    <ImagePreview download={true} url={selectedImageURL} isOpen={selectedImageURL == getDetectImageURL(sortResult.id)} onClose={handleClosePreview} titleName={sortResult.id}/>
                                    
                                    <Box sx={{
                                        position: 'absolute',
                                        width: {
                                            xs: '70%',
                                            md: '40%'
                                        },
                                        top: '4px', left: '4px',
                                        textAlign: 'left'
                                    }}>
                                        {
                                        // sortResult.labels.slice().sort((a, b) => b.num - a.num).map((label)=>(
                                        // 選択済みラベルのsort結果と選択外のsort結果結合
                                        sortResult.labels.filter((label) => selectLabels.includes(label.name)).sort((a, b) => b.num - a.num)
                                        .concat(
                                            sortResult.labels.filter((label) => !selectLabels.includes(label.name)).sort((a, b) => b.num - a.num)
                                        ).map((label)=>(
                                            <Chip
                                            onClick={async()=>{
                                                // sortType == 'label' && setSelectLabels(selectLabels?.concat([label.name]));
                                                if (!DBState) await initDBState();
                                                if (!selectLabels.includes(label.name)) setSelectLabels(selectLabels?.concat([label.name]));
                                                setSortType('label');
                                            }}
                                            onDelete={()=>{
                                                setSelectLabels(selectLabels.filter((selectLabel) => selectLabel != label.name));
                                            }}
                                            deleteIcon={selectLabels?.includes(label.name) ? <ClearOutlined /> : <></>}
                                            label={label.num+' '+label.name} // 画像上に taglist を表示
                                            variant='filled'
                                            size={ isMD ? 'small' : 'medium' }
                                            sx={(theme)=>({
                                                mb:'1px',
                                                mr:'1px',
                                                background: selectLabels?.includes(label.name) ? 
                                                    alpha(theme.palette.info.main, 0.5) : alpha(theme.palette.background.paper, 0.5),
                                                backdropFilter: "blur(2px)",
                                                color: theme.palette.text.primary
                                            })}/>
                                        ))}
                                    </Box>
                                
                                </Box>
                            </Box>
                        ))}
                    </Box>:

                    <Box>
                        <h4>結果なし</h4>
                    </Box>
                }
            </Grid>
            {sortResults?.length > 0 && 
            <Grid item xs={12}>
                <Box sx={{display: 'inline-block'}} >
                <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
                >
                    <Pagination
                        count={pages}
                        showFirstButton showLastButton
                        variant='outlined'
                        shape='rounded' // 角を丸めたデザイン
                        onChange={(event, value)=>{
                            setPage(value);
                        }}
                    />
                    
                    <FormControl sx={{width: 100}}>
                        <InputLabel variant='outlined'>ページ毎</InputLabel>
                        <Select
                        size='small'
                        variant='standard'
                        value={pageSize}
                        onChange={(event)=>{
                            setPageSize(event.target.value as number);
                        }}
                        label='ページ毎'
                        >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                </Box>
            </Grid>
            }

        </Grid>

    </Box>
    );
};

export default Images;
