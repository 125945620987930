const APIServer = {
    url: process.env.REACT_APP_API_URL as string
}
const wsServer = {
    url: process.env.REACT_APP_WS_URL as string
}

const imagePath = {
    logo: "/images/logo.png"
}

const pages: string[] = [

]

export default { APIServer, wsServer, imagePath, pages }