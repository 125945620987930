import config from './config';
import { sleep } from './utils/util'
import { wsContext } from './utils/connection';
import useWebSocket from 'react-use-websocket';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { CssBaseline, ThemeProvider, useMediaQuery, GlobalStyles } from '@mui/material';

import { wsMessage } from './types/connection';
import { Theme, DarkModeContext } from './utils/theme';
import Header from './components/Header';
import Home from './pages/Home';
import Detect from './pages/Detect/Detect';
import DetectCamera from './pages/Detect/Camera';
import Pose from './pages/Pose/Pose';
import PoseCamera from './pages/Pose/Camera';
import { messageToJson } from './utils/converter';
import Upload from './pages/Images/Upload';
import Images from './pages/Images/Images';

const App: FC = () => {
    //////// websocket
    const [sessionId, setSessionId] = useState('');
    const [messageHistory, setMessageHistory] = useState<wsMessage[]>([]);
    const [connectState, setConnectState] = useState(false);
    const {
        // sendMessage,
        sendJsonMessage,
        lastMessage,
        // lastJsonMessage,
        // readyState,
        // getWebSocket,
    } = useWebSocket(config.wsServer.url, {
        onOpen: () => {
            setConnectState(true);
            console.log("start connection!")
        },
        shouldReconnect: (closeEvent) => {
            setConnectState(false);
            console.log("reconnecting...")
            return true;
        },
    });

    useEffect(() => { // on message
        if (!lastMessage) return;

        const jsonData = messageToJson(lastMessage.data);
        if (messageHistory.length >= 100) { // 履歴 100以上は消してく
            setMessageHistory(messageHistory.slice(1).concat(jsonData));
        } else {
            setMessageHistory(messageHistory.concat(jsonData));
        }

        // init したりするよう
        switch (jsonData.type) {
            case 'createSession':
                    setSessionId(jsonData.sessionId!);
                break;
            default:
                break;
        }
    }, [lastMessage, setMessageHistory]);

    // useEffect(() => { // on message history
    //     console.log(messageHistory)
    // }, [messageHistory])

    //////// darkmode
    const [darkMode, setDarkMode] = useState(false);
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    useEffect(() => { // OSのtheme変更時
        if (localStorage.getItem('darkMode') === 'true') {
            setDarkMode(true);
        } else if (localStorage.getItem('darkMode') === 'false') {
            setDarkMode(false);
        } else if (prefersDarkMode) { // クライアントのtheme
            setDarkMode(true);
        } else {
            setDarkMode(false);
        }
    }, [prefersDarkMode]);

    return (
        <wsContext.Provider value={{
            connectState: connectState,
            sessionId: sessionId,
            lastMessage: lastMessage,
            messageHistory: messageHistory,
            sendJsonMessage: sendJsonMessage
        }}>
        <DarkModeContext.Provider value={useMemo(() => ({
                toggleDarkMode: () => {
                    setDarkMode((prevDarkMode: boolean) => {
                        if (prevDarkMode) {
                            localStorage.setItem('darkMode', 'false');
                            return false;
                        } else {
                            localStorage.setItem('darkMode', 'true');
                            return true;
                        }
                    });
                },
                setDarkMode: (darkMode: Boolean) => {
                    setDarkMode(() => {
                        if (darkMode) {
                            localStorage.setItem('darkMode', 'true');
                            return true;
                        } else {
                            localStorage.setItem('darkMode', 'false');
                            return false;
                        }
                    });
                },
                state: darkMode,
            }),
            [],
        )}>
        <ThemeProvider theme={Theme(darkMode)}>
            <CssBaseline />
            <GlobalStyles styles={(theme) => ({
                a: {
                    color: theme.palette.secondary.light,
                    textDecoration: 'none',
                    transition: '0.2s'
                },
                'a:hover': {
                    color: theme.palette.secondary.dark
                }
            })} />

            <Header />
            
            <BrowserRouter>            
            
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/detect" element={<Detect />} />
                <Route path="/detect/camera" element={<DetectCamera />} />
                
                <Route path="/pose" element={<Pose />} />
                <Route path="/pose/camera" element={<PoseCamera />} />

                <Route path="/upload" element={<Upload />} />
                <Route path="/images" element={<Images />} />
            </Routes>
            
            </BrowserRouter>
        </ThemeProvider>
        </DarkModeContext.Provider>
        </wsContext.Provider>
    )
}

export default App;
