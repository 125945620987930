import config from "../config";


export async function uploadImage(file: File) {
    const formData = new FormData();
    formData.append('image', file);
    return await fetch(config.APIServer.url+'/uploadImage', {
        method: 'POST',
        body: formData,
    });
}

export default uploadImage;