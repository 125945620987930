import { wsMessage } from "../types/connection";

export function image64ToFile(image64: string) {
    // Base64データをBlobに
    const byteCharacters = atob(image64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/jpeg' }); // 画像のMIMEタイプに合わせて指定

    // Blobをファイルに変換
    return new File([blob], 'image.jpg', { type: 'image/jpeg' }); // ファイル名とMIMEタイプを指定
}

export async function fileToImage64(imageFile: File) {
    const byteArray = Array.from<number>(new Uint8Array(await imageFile.arrayBuffer()));
    let encodedStr = '';
    for (let i = 0; i < byteArray.length; i += 1024) {
        encodedStr += String.fromCharCode.apply(
            null, byteArray.slice(i, i + 1024)
        )
    }
    return window.btoa(encodedStr);
}

export function blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.addEventListener('load', () => {
            const dataUrl = reader.result;

            if (dataUrl) {
                const base64 = btoa(dataUrl as string);
                resolve(base64);
            } else {
                console.error('Couldn\'t fetch data URL.')
            }
        });

        reader.readAsDataURL(blob);
    });
}

export async function resizeImage(imageFile: File, maxWidth: number, maxHeight:number): Promise<File> {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = URL.createObjectURL(imageFile);

        img.onload = () => {
            const canvas = document.createElement('canvas');
            let width = img.width;
            let height = img.height;

            if (width > maxWidth || height > maxHeight) {
                const aspectRatio = width / height;

                if (width > maxWidth) {
                    width = maxWidth;
                    height = width / aspectRatio;
                }

                if (height > maxHeight) {
                    height = maxHeight;
                    width = height * aspectRatio;
                }
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            if (!ctx) return;
            ctx.drawImage(img, 0, 0, width, height);
            canvas.toBlob((blob)=>{
                if (!blob) return reject(new Error('Failed to create blob.'));
                resolve(new File([blob], imageFile.name, { type: 'image/jpeg' })); // ファイル名とMIMEタイプを指定
            },
            'image/jpeg', '0.1'); // jpegで圧縮
            // 不要になったBlob URLを解放
            URL.revokeObjectURL(img.src);
        }
    });
}

export function fetchBlob(blobUrl: string): Promise<Blob> {
    return new Promise((resolve, reject) => {
        fetch(blobUrl)
            .then((v) => (
                v.blob().then(resolve).catch(reject)
            ))
            .catch(reject);
    });
}

export function messageToJson(message: string) {
    return JSON.parse(message) as wsMessage;
}


export default { image64ToFile, fileToImage64, blobToBase64, resizeImage, fetchBlob, messageToJson }