import { FC, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Input,
  Slider,
  TextField,
} from '@mui/material';
import {
  AddOutlined,
  DeleteForeverOutlined,
  FileDownloadOutlined,
  SearchOutlined,
} from '@mui/icons-material';
import { ImageFilePreview } from '../../components/Window';
import { image64ToFile, resizeImage } from '../../utils/converter';
import { LinearProgressWithLabel } from '../../components/Progress';
import { Link } from 'react-router-dom';
import * as DetectImage from '../../types/detectImage';
import { detectImage } from '../../api/detectImage';
import { Models } from '../../types/models';
import getModels from '../../api/getModels';

const Detect: FC = () => {
  const [processing, setProcessing] = useState(false);
  const [processingNum, setProcessingNum] = useState(0);
  const [processedNum, setProcessedNum] = useState(0);
  const [models, setModels] = useState<Models | undefined>(undefined);
  const [modelSelectFieldLoading, setModelSelectFieldLoading] =
    useState<boolean>(false);
  const [selectModelId, setSelectModelId] = useState<string | undefined>(
    undefined
  );
  const [confidenceThreshold, setConfidenceThreshold] = useState(0.2); // 信頼度閾値(Confidence)
  const [IOUThreshold, setIOUThreshold] = useState(0.45); // 評価指標(IoU)

  // const wsContext = useWs();
  const imageDetect = async () => {
    setProcessing(true);
    setProcessingNum(imageFiles.length);

    let processedNum_ = processedNum,
      detectedImageFiles_ = detectedImageFiles;
    // for(let i = 0; imageFiles.length > i; i++){
    //     try {
    //         const response = await detectImage(imageFiles[i]);

    //         if (response.ok) {
    //             const jsonData: DetectImage.Response = await response.json();
    //             if (!jsonData.image || jsonData.image == '') return;
    //             detectedImageFiles_ = detectedImageFiles_.concat(image64ToFile(jsonData.image))
    //             setDetectedImageFiles(detectedImageFiles_);
    //         } else {
    //             console.error('File upload failed');
    //         }
    //     } catch (error) {
    //         console.error('ERROR: image detect', error);
    //     }
    //     setProcessedNum(++processedNum_);
    //     if (processedNum_ >= imageFiles.length) {
    //         setProcessing(false);
    //         setProcessingNum(0);
    //         setProcessedNum(0);
    //     }
    // }
    imageFiles.forEach(async (file, i) => {
      try {
        const response = await detectImage(
          file,
          selectModelId,
          confidenceThreshold,
          IOUThreshold
        );

        if (response.ok) {
          const jsonData: DetectImage.Response = await response.json();
          if (!jsonData.image || jsonData.image == '') return;
          detectedImageFiles_ = detectedImageFiles_.concat(
            image64ToFile(jsonData.image)
          );

          const imageLimit = 20;
          if (detectedImageFiles_.length > imageLimit)
            detectedImageFiles_.shift(); // リザルトが多すぎたら消す

          setDetectedImageFiles(detectedImageFiles_);
        } else {
          console.error('File upload failed');
        }
      } catch (error) {
        console.error('ERROR: image detect', error);
      }
      setProcessedNum(++processedNum_);
      if (processedNum_ >= imageFiles.length) {
        setProcessing(false);
        setProcessingNum(0);
        setProcessedNum(0);
      }
    });
  };

  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [detectedImageFiles, setDetectedImageFiles] = useState<File[]>([]);

  const addImageFiles = async (fileList: FileList) => {
    const files = Array.from(fileList).filter(
      (file) =>
        file.type.startsWith('image/png') || file.type.startsWith('image/jpeg')
    );
    // && file.size <= 800000); // weboskcet使って送ると1MB超えると死ぬっポイ
    const imageLimit = 20;
    if (files.length >= imageLimit) files.splice(-(files.length - imageLimit)); // 添付したfileが最大file以上だったら削る

    for (let i = 0; files.length > i; i++) {
      files[i] = await resizeImage(files[i], 1200, 1200); // 重くなるから圧縮
    }
    if (imageFiles.length >= imageLimit) {
      // 画像20以上は消してく
      setImageFiles(imageFiles.slice(files.length).concat(files));
    } else {
      setImageFiles(imageFiles.concat(files));
    }
    return;
  };

  const deleteImageFile = (targetNum: number) => {
    const _ = [...imageFiles];
    _.splice(targetNum, 1);
    setImageFiles(_);
    return;
  };
  const deleteDetectedImageFiles = (targetNum: number) => {
    const _ = [...detectedImageFiles];
    _.splice(targetNum, 1);
    setDetectedImageFiles(_);
    return;
  };

  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [selectedFileNum, setSelectedFileNum] = useState<number>(0);

  const handleImageClick = (file: File, fileNum: number) => {
    setSelectedFile(file);
    setSelectedFileNum(fileNum);
  };
  const handleClosePreview = () => {
    setSelectedFile(undefined);
  };

  return (
    <Box
      sx={{
        textAlign: 'center',
        padding: '20px',
      }}
    >
      <h1>yolov7 - 画像検出</h1>
      <p>
        カメラアリルタイム版は<Link to="/detect/camera">こちら</Link>
      </p>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            loading={modelSelectFieldLoading}
            loadingText={'ラベルを取得中...'}
            onOpen={async () => {
              setModelSelectFieldLoading(true);
              setModels((await getModels()) ?? undefined);
              setModelSelectFieldLoading(false);
            }}
            onChange={(event, newValue) => {
              setSelectModelId(newValue?.id);
            }}
            getOptionLabel={(option) => option.name}
            options={models?.detect ?? []}
            noOptionsText={'モデルが存在しません'}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {modelSelectFieldLoading && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                      {
                        params.InputProps.endAdornment // 閉じるボタン
                      }
                    </>
                  ),
                }}
                variant="outlined"
                label="推論するモデルを選択"
                placeholder={
                  models == undefined
                    ? 'モデル名を入力'
                    : models.detect.map((modelData) => modelData.name)[
                        Math.floor(Math.random() * models.detect.length)
                      ]
                } // モデルの中から毎回ランダムで適当に表示
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>信頼度閾値(Confidence)</Grid>
            <Grid item xs>
              <Slider
                value={confidenceThreshold}
                onChange={(event: Event, newValue: number | number[]) => {
                  setConfidenceThreshold(newValue as number);
                }}
                valueLabelDisplay="auto"
                step={0.01}
                min={0.01}
                max={1}
              />
            </Grid>
            <Grid item>
              <Input
                value={confidenceThreshold}
                size="small"
                onChange={(event) => {
                  setConfidenceThreshold(Number(event.target.value));
                }}
                onBlur={() => {
                  if (confidenceThreshold < 0.01) {
                    setConfidenceThreshold(0.01);
                  } else if (confidenceThreshold > 1) {
                    setConfidenceThreshold(1);
                  }
                }}
                inputProps={{
                  step: 0.01,
                  min: 0.01,
                  max: 1,
                  type: 'number',
                }}
                sx={{
                  width: 58,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>評価指標(IoU)</Grid>
            <Grid item xs>
              <Slider
                value={IOUThreshold}
                onChange={(event: Event, newValue: number | number[]) => {
                  setIOUThreshold(newValue as number);
                }}
                valueLabelDisplay="auto"
                step={0.01}
                min={0.01}
                max={1}
              />
            </Grid>
            <Grid item>
              <Input
                value={IOUThreshold}
                size="small"
                onChange={(event) => {
                  setIOUThreshold(Number(event.target.value));
                }}
                onBlur={() => {
                  if (IOUThreshold < 0.01) {
                    setIOUThreshold(0.01);
                  } else if (IOUThreshold > 1) {
                    setIOUThreshold(1);
                  }
                }}
                inputProps={{
                  step: 0.01,
                  min: 0.01,
                  max: 1,
                  type: 'number',
                }}
                sx={{
                  width: 58,
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid item xs={12}>
            <div style={{
                display: 'flex',
                flexWrap: 'wrap'
            }}>
                {
                    [...Array(5)].map((_, i)=>(
                        <div>
                            <Box sx={(theme)=>({
                                width: 200,
                                height: 200,
                                borderStyle: 'solid',
                                // borderColor: alpha(theme.palette.primary.light, 0.2),
                                borderWidth: 1,
                                borderRadius: 2
                            })}></Box>
                        </div>
                    ))
                }
                </div>
            </Grid> */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: 'repeat(auto-fill, minmax(100px, 1fr))',
                md: 'repeat(auto-fill, minmax(150px, 1fr))',
              },
              gap: '10px',
            }}
          >
            <Box
              sx={{
                gridColumn: {
                  xs: 'span 3', // xsの場合、2マス分の幅を持たせる
                  md: 'span 2', // 'auto',   // smの場合、通常の幅を持たせる
                },
                // width: { xs: 'auto', sm: 150 },
                width: 'auto',
                height: { xs: 100, md: 150 },
              }}
            >
              <Button
                onDrop={(e) => {
                  e.preventDefault();
                  if (e.dataTransfer.types.indexOf('Files') == -1) return; // file以外
                  addImageFiles(e.dataTransfer.files);
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                  e.dataTransfer.dropEffect = 'link';
                }}
                component="label"
                variant="contained"
                sx={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <AddOutlined />
                画像をドロップ
                <br />
                もしくはクリック
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    e.preventDefault();
                    if (!e.target.files) return;
                    addImageFiles(e.target.files);
                  }}
                />
              </Button>
            </Box>
            {imageFiles
              .slice()
              .reverse()
              .map((imageFile, i) => (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'relative',
                    width: {
                      xs: 100,
                      md: 150,
                    },
                    height: {
                      xs: 100,
                      md: 150,
                    },
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderRadius: 2,
                    borderColor: (theme) => theme.palette.grey[800],
                    overflow: 'hidden',
                  }}
                >
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      deleteImageFile(imageFiles.length - i - 1);
                    }}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      cursor: 'pointer',
                      color: (theme) => theme.palette.error.main,
                    }}
                  >
                    <DeleteForeverOutlined />
                  </IconButton>
                  <Box
                    component="img"
                    onClick={() =>
                      handleImageClick(imageFile, imageFiles.length - i - 1)
                    }
                    src={URL.createObjectURL(imageFile)}
                    sx={{ height: '120%' }}
                  />
                  <ImageFilePreview
                    file={selectedFile}
                    isOpen={
                      selectedFile &&
                      selectedFileNum == imageFiles.length - i - 1
                    }
                    onClose={handleClosePreview}
                    deleteImageFile={deleteImageFile}
                    targetNum={selectedFileNum}
                  />
                </Box>
              ))}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Button
            disabled={processing || !imageFiles.length}
            component="label"
            variant="contained"
            color="success"
            sx={{
              background: (theme) => theme.palette.success.light,
              width: '100%',
              height: '100%',
            }}
            onClick={imageDetect}
          >
            {processing ? (
              <CircularProgress size={20} sx={{ marginRight: 1 }} />
            ) : (
              <SearchOutlined sx={{ marginRight: 1 }} />
            )}
            画像を検出！{processing ? ' -  処理中...' : null}
          </Button>
        </Grid>
        {processing ? (
          <Grid item xs={12}>
            <LinearProgressWithLabel
              value={(processedNum / processingNum) * 100}
            />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: 'repeat(auto-fill, minmax(130px, 1fr))',
                md: 'repeat(auto-fill, minmax(180px, 1fr))',
              },
              gap: '10px',
            }}
          >
            {detectedImageFiles
              .slice()
              .reverse()
              .map((imageFile, i) => (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'relative',
                    width: {
                      xs: 130,
                      md: 180,
                    },
                    height: {
                      xs: 130,
                      md: 180,
                    },
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderRadius: 2,
                    borderColor: (theme) => theme.palette.grey[800],
                    overflow: 'hidden',
                  }}
                >
                  <IconButton // delete button
                    aria-label="delete"
                    onClick={() => {
                      deleteDetectedImageFiles(
                        detectedImageFiles.length - i - 1
                      );
                    }}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      cursor: 'pointer',
                      color: (theme) => theme.palette.error.main,
                    }}
                  >
                    <DeleteForeverOutlined />
                  </IconButton>

                  <a
                    href={URL.createObjectURL(imageFile)}
                    download={new Date().getTime()}
                  >
                    <IconButton // download button
                      aria-label="download"
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 25,
                        cursor: 'pointer',
                        color: (theme) => theme.palette.secondary.dark,
                      }}
                    >
                      <FileDownloadOutlined />
                    </IconButton>
                  </a>

                  <Box
                    component="img"
                    onClick={() =>
                      handleImageClick(
                        imageFile,
                        detectedImageFiles.length - i - 1
                      )
                    }
                    src={URL.createObjectURL(imageFile)}
                    sx={{ height: '120%' }}
                  />
                  <ImageFilePreview
                    download={true}
                    isOpen={
                      selectedFile &&
                      selectedFileNum == detectedImageFiles.length - i - 1
                    }
                    file={selectedFile}
                    onClose={handleClosePreview}
                    deleteImageFile={deleteDetectedImageFiles}
                    targetNum={selectedFileNum}
                  />
                </Box>
              ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Detect;
